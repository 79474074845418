<script>
	import localService from "@/service"
	import VueEvent from '../service/events'
	import {
		ref
	} from 'vue'

	const count = ref(0)
	export default {
		data() {
			return {
				industries:[],
				count_map:{}
			}
		},
		mounted() {
			this.loadInstries()
		},
		methods: {
			loadInstries(){
				localService.http.request({
					url: "/ds/iso.platform.list_industries",
					params: {}
				}).then(res => {
					this.industries = res.data.industries;
					this.count_map=res.data.count_map;
				})
			},
			onIndustryItemClick(item,clickable){
				if(!clickable)
					return;
				
				let data = localService.utils.encodeCode(JSON.stringify({
					industry: item
				}))
				this.$router.push({
					path: "/search",
					query: {
						filter: data
					}
				})
			}
		}
	}
</script>

<template>
	<div class="industry-main">
		<div class="title">行业类别
		</div>
		<div class="industry_grid">
			<!--
			<el-row :gutter="30">
				<el-col :span="8"><div class="grid-content">
					<div class="grid-title">
						<span class="block">
							质量管理体系
						</span>
					</div>
				</div></el-col>
				<el-col :span="8"><div class="grid-content"></div></el-col>
				<el-col :span="8"><div class="grid-content"></div></el-col>
			</el-row>
			-->
			<el-row :gutter="30">
				<el-col :span="6" v-for="(item, index) in industries" class="cell">
					<div @click="onIndustryItemClick(item[2],count_map[item[2]]?true:false)" :class="count_map[item[2]]?'industry-item':'industry-item-disabled'">
						{{item[2]}}({{count_map[item[2]]?count_map[item[2]]:"0"}})
					</div>
				</el-col>
				
			</el-row>
		</div>
		
	</div>
</template>
<style scoped>
	.industry-main {
		width: 100vw;
		color: #000;
		padding-top: 10px;
		margin-top: 20px;
		
		.title{
			height: 48px;
			line-height: 48px;
			font-size: 24px;
			font-weight: normal;
			font-family: 黑体;
			width: 80vw;
			margin: 0px 10vw 12px 10vw;
			border-radius: 12px;
			background-color: #fff;
			
		}
		
		.industry_grid{
			width: 80vw;
			margin: 0px 10vw;
			padding: 10px 15px;
			background-color: #fff;
			filter: blur(0px);
			border-radius: 12px;

			.industry-item{
				text-align: left;
				line-height: 36px;
				height: 36px;
				padding: 3px;
				cursor: pointer;
				color: #000;
			}
			.industry-item:hover{
				text-decoration: underline;
			}
			.industry-item-disabled {
				color: #666;
				text-align: left;
				line-height: 36px;
				height: 36px;
				padding: 3px;
			}
			.grid-content{
				padding: 8px 10px;
				.grid-title{
					text-align: left;
					.block{
						color:#000;
						display: inline-block;
						font-size: 18px;
						font-weight: bold;
						padding: 3px 3px;
						border-bottom: solid 3px #0e5590;
					}
				}
				height: 200px;
				background-color: #f9f9f9;
				filter: blur(1px);
				/*box-shadow: 3px 3px 5px rgba(220, 220, 220, 0.1);*/
				border-radius: 8px;
				margin-bottom: 25px;
			}
		}
		
	}
</style>
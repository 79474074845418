import Vue from 'vue'
import App from './App.vue'
import router from './router'
import store from './store'
import './common/css/base.css'

import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';


Vue.config.productionTip = false
Vue.use(ElementUI);
new Vue({
  router,
  store,
  //vuetify,
  render: h => h(App)
}).$mount('#app')

router.afterEach((to,from,next) => {
 window.scrollTo(0,0);
})
<script>
	import {
		ref
	} from 'vue'
	import localService from "@/service"
	import VueEvent from '../service/events'
	import store from "../store"
	export default {
		props: {},
		data() {
			const defaultForm = Object.freeze({
				project_name: '',
				last: '',
				bio: '',
				favoriteAnimal: '',
				terms: false,
			})
			return {
				form: Object.assign({}, defaultForm),
				rules: {
					// name: [val => (val || '').length > 0 || '项目名称不能为空'],
					name: [val => /^.*[\u4e00-\u9fa5].*$/.test(val) || '项目名称需包含至少一个中文字符']
				},
				formLabelWidth: '120px',
				conditions: false,
				content: '',
				snackbar: false,
				terms: false,
				defaultForm,
				sessionId: "",
				tLoginInterval: -1,
				showLogin: false,
				clientY: 0,
				dialog: false,
				showCreator: false,
				showLoading: false,
				toPage: "",
				showMessage: false,
				message: "",
				rules: {
					project_name: [
						{ required: true, message: '请输入项目名称', trigger: 'blur' },
						{ min: 3, max: 16, message: '长度在 3 到 16 个字符', trigger: 'blur' }
					],
					bio: [
						{ required: true, message: '请输入项目描述', trigger: 'blur' },
						{ min: 3, max: 36, message: '长度在 3 到 36 个字符', trigger: 'blur' }
					],
				}
			}
		},
		computed: {
		},
		watch: {
			dialog(newValue, oldValue) {
				if (newValue) {
					this.startCheck()
				} else {
					this.stopCheck()
				}
			}
		},
		mounted() {			
			VueEvent.on("execLogin", (link) => {
				this.toPage = link;
				let _this = this;
				_this.dialog = true;
			})

			VueEvent.on("execCreate", (item) => {
				//alert("create using..."+JSON.stringify(item))
				this.showCreator = true;
			})
			
			VueEvent.on("execMessage", (text) => {
				this.message = text;
				this.showMessage = true;

				this.$notify({
					title: '操作已完成.',
					message: text,
					type: 'success',
					position: 'bottom-right'
				});
			})

			localService.http.request({
				url: "/ds/iso.public.get_session",
				params: {}
			}).then(res => {
				this.sessionId = res.data.sid;
				// console.log("!!!!sid=" + this.sessionId)
			});

			this.clientY = ($(window).height() - 400) / 2;
			$(".login-main").css("top", this.clientY + "px")
			window.addEventListener("scroll", function() {
				var scrollHeight = $(window).scrollTop();
				this.clientY = ($(window).height() - 400) / 2 + scrollHeight
				$(".login-main").css("top", this.clientY + "px")
			})
		},
		beforeDestroy(){
			VueEvent.off("execLogin")

			VueEvent.off("execCreate")
			
			VueEvent.off("execMessage")
		},

		methods: {
			loginCheck(userInfo) {
				console.log("检查是否登录")
				var _this = this;
				localService.http.request({
					url: "/login",
					params: {
						ext: JSON.stringify(userInfo)
					}
				}).then(res => {
					console.log("!!!!result=" + res.data)
					if (res.data == "true" || res.data == true) {
						this.dialog = false
						// console.log("login...." + JSON.stringify(userInfo))
						userInfo.id = userInfo.openId;
						this.$store.commit("setUserInfo", userInfo);
						VueEvent.emit("userLogin", userInfo);
						//判断登录前的操作，登录完成后继续操作
						let _this = this;
						switch (this.toPage){
							case "/favs":
								_this.message = "登录成功，即将跳转到我的收藏";
								_this.showMessage = true;
								setTimeout(function(){
									_this.$router.push({
										path: _this.toPage
									})
								},1500)
								break;
							case "create":
								_this.message = "登录成功，即将打开创建项目窗口";
								_this.showMessage = true;
								setTimeout(function(){
									_this.showMessage = false;
									_this.showCreator = true;
								},1500)
								break;
							case "collect":
								// _this.message = "登录成功";
								// _this.showMessage = true;
								VueEvent.emit("execCollect");
								break;
							default:
								_this.message = "登录成功";
								_this.showMessage = true;
								break;
						}
					}
				});
			},
			startCheck() {
				let inst = this
				let sessionId = this.sessionId
				this.tLoginInterval = setInterval(function() {
					////////////////////////begin
					localService.http.request({
						url: "/ds/site.get_user_login",
						params: {
							session_id: sessionId
						}
					}).then(res => {
						if (res.data.success == "true") {
							var userInfo = JSON.parse(res.data.userInfo);
							if (userInfo.nickname == "微信用户") {
								console.warn("未授权...");
								return;
							}
							clearInterval(this.tLoginInterval);
							this.tLoginInterval = 0;
							//console.log("get user::"+JSON.stringify(userInfo));
							inst.loginCheck(userInfo)
						}
					});
					////////////////////////end
				}, 1000)
			},
			stopCheck() {
				if (this.tLoginInterval > 0) {
					clearInterval(this.tLoginInterval);
					this.tLoginInterval = 0;
				}
			},
			onLogin() {
				//this.dialog=false
				//VueEvent.emit("userLogin",{id:"10001",name:"王小建"});
			},
			submitForm(formName) {
				this.$refs[formName].validate((valid) => {
				if (valid) {
					this.onCreate(valid)
				} else {
					console.log('error submit!!');
					return false;
				}
				});
			},
			async onCreate(valid) {
				
				if(valid){
					var raw = [];
					//raw.push({name:"docid",value:ret.doc_id});
					raw.push({
						name: "project_name",
						value: this.form.project_name
					});
					raw.push({
						name: "config_options",
						value: {
							type: "product",
							template_id: store.state.currentTemplate.id,
							config_id: store.state.currentTemplate.cert_type
						}
					});
					var industry = JSON.parse(store.state.currentTemplate.industry);
					var strIndustry = industry.length > 0 ? industry[0].path : "未定义";
					raw.push({
						name: "industry",
						value: strIndustry
					});
					raw.push({
						name: "classify",
						value: "cert_projects"
					});
					//alert("rw=="+JSON.stringify(raw))
					localService.http.request({
						url: "/ds/iso.public.create_project",
						params: {
							from: 1,
							table: "cert_projects",
							raw: JSON.stringify(raw)
						}
					}).then(res => {
						if (res.data.success) {
							this.showCreator = false;
							this.showLoading = true;
							let _this = this
							
							_this.$router.push({
									path: "/project",
									query: {
										
									}
								}, ()=>{}, ()=>{})
							/*
							setTimeout(function(){
								_this.$router.push({
									path: "/projectShow",
									query: {
										
										data: localService.utils.encodeCode(JSON.stringify(res.data.bean))
									}
								}, ()=>{}, ()=>{})
							},2000)*/
						}
					})
				}
			}
		}
	}
</script>

<template>
	<div class="text-center" >
		<el-dialog
			:lock-scroll="false"
			title="用户登录"
			:visible.sync="dialog"
			width="30%"
			>
			<span>
				<div style="display: flex;align-items: center;flex-direction: column;padding-top:30px;padding-bottom:30px;">
					<div style="background-color: #ffffff; padding: 20px; border-radius: 10px; box-shadow: 0 0 10px rgb(0 0 0 / 10%);">
						<img src="/ds/site.create_qrcode_login2?w=250&h=250" style="border: solid 0px red;">
					</div>
					<div style="text-align: center; font-size: 16px; color: #333; margin-top: 20px;">
						微信扫码登录
					</div>
				</div>
			</span>
		</el-dialog>
		

		<el-dialog :visible.sync="showCreator" title="创建项目" width="600">
			<el-card>
				
				<div style="padding:15px 30px 0 30px;">
						<!--begin-->
						<el-form :model="form" :rules="rules" ref="form">
							<el-form-item label="项目名称" :label-width="formLabelWidth" prop="project_name">
								<el-input v-model="form.project_name" autocomplete="off"></el-input>
							</el-form-item>
							<el-form-item label="项目说明" :label-width="formLabelWidth" prop="bio">
								<el-input   type="textarea" v-model="form.bio"></el-input>
							</el-form-item>
						</el-form>
						<!--
						<v-form ref="form">
							<v-container fluid>
								<v-row>
									<v-col cols="12">
										<v-text-field v-model="form.project_name" :rules="rules.name" color="#1b66e6"
											label="项目名称" required></v-text-field>
									</v-col>
									<v-col cols="12">
										<v-textarea v-model="form.bio" color="#1b66e6" rows="3">
											<template v-slot:label>
												<div>
													项目说明 <small></small>
												</div>
											</template>
										</v-textarea>
									</v-col>
								</v-row>
							</v-container>
						</v-form>
						-->
						<!--end-->
						<p style=" background: #ffffff; padding: 5px 15px; margin: 0 10px; color: #333; font-size: 14px;">
							
							<i class="el-icon-info" style="color: #ccc; vertical-align: top; margin-right: 5px; font-size: 22px;"></i>
							创建的项目仅用于在平台上的交流共享，请勿用作其它商业用途
						</p>
				</div>
				<!--
				<v-card-actions style="padding: 20px 30px;">
					<v-spacer></v-spacer>
					<v-btn color="primary" height="36px" min-width="100px" @click="onCreate">立即创建</v-btn>
				</v-card-actions>
				-->
			</el-card>
			<div slot="footer" class="dialog-footer">
				<el-button @click="showCreator = false">取 消</el-button>
				<el-button type="primary" @click="submitForm('form')">立即创建</el-button>
			</div>
		</el-dialog>

		<v-dialog v-model="showLoading" :scrim="false" persistent width="200px">
			<v-card color="#fff">
				<v-card-text style="padding: 25px 30px; text-align: center; font-size: 16px; color: #333;">
					项目创建中...
					<v-progress-linear indeterminate color="#1b66e6" :height="7" style="margin-top: 20px;"></v-progress-linear>
				</v-card-text>
			</v-card>
		</v-dialog>
		<!--
		<v-snackbar v-model="showMessage" timeout="1500">
			<div style="text-align: center; font-size: 15px;">
				{{message}}
			</div>
		</v-snackbar>
	-->
	</div>
</template>
<style scoped>

	.v-messages__message {
		line-height: 20px;
	}

	.login-main {
		width: 50%;
		height: 400px;
		border: solid 1px #efefef;
		position: absolute;
		left: 25%;
		top: 20%;
		background-color: #fff;
		z-index: 9999;
		box-shadow: 1px 0px 10px 2px rgba(100, 100, 100, 0.5);
		display: flex;
		padding: 10px;
	}

	.kl-mask-load {
		z-index: 9998;
		background-color: #000;
		opacity: 0;
		position: absolute;
		left: 0;
		right: 0;
		bottom: 0;
		top: 0;
		transition: opacity .2s ease-in;
		display: grid;
		align-items: center;
		place-items: center;
		pointer-events: none;
	}

	.kl-mask-load.show {
		opacity: 0.8;
		pointer-events: auto;
		height: 100%;
		transition: opacity .2s ease-in;
	}

</style>
<script>
	import MainHeader from '../components/MainHeader.vue'
	import MainFooter from '../components/MainFooter.vue';
	import UserLogin from '@/components/UserLogin.vue';
	import IndustryList from '@/components/IndustryList.vue';
	import LastPublished from '../components/LastPublished.vue';
	import HotDownload from '../components/HotDownload.vue';
	export default {
		name: 'MainView',
		components: {
			MainHeader,
			IndustryList,
			LastPublished,
			HotDownload,
			MainFooter,
			UserLogin
		},
		data: () => ({
			showBanner: false,
		}),
		created() {
			 document.addEventListener('scroll', this.handleScroll, true);
		},
		methods: {
			handleScroll(){
				this.$nextTick(()=>{
					var top = document.body.scrollTop || document.documentElement.scrollTop || window.pageYOffset;
					if(top >= 220){
						this.showBanner = true;
					}else{
						this.showBanner = false;
					}
				})
			},
			toList() {
				this.$router.push({
					path: "/list",
				})
			}
		}
	};
</script>

<template>
	<div class="page_body">
		<MainHeader theme=""  showSearch="true" showMenu="true" menuId="0"  showBanner="true"/>
		<IndustryList/>
		<LastPublished/>
		<HotDownload/>
		<MainFooter />
		<UserLogin />
	</div>
</template>

<style scoped>
	.page_body{
		background-color: #f8f8f8;
	}

	.path{
		width: 85vw;
	}
	.hot {
		width: 100vw;
		padding: 0vw 10vw;
		
	}

	.caption {
		width: 85vw;
		min-width: 1200px;
		margin: 0 auto;
		font-size: 18px !important;
		font-weight: bold;
		color: #333;
		padding: 20px 0;
		line-height: 30px;
	}


	.caption label {
		font-size: 14px;
		color: #333;
		margin-left: 30px;
	}
	

	.caption a {
		float: right;
		font-size: 16px;
		color: #666;
		cursor: pointer;
	}

	.caption a:hover {
		color: #333;
	}

	.caption a i {
		vertical-align: sub;
		color: #999;
	}

	.caption a:hover i {
		color: #666;
	}

	.hotWrap {
		width: 80vw;
		min-width: 1200px;
	}
</style>
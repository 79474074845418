<script>
	import {
		h,
		ref
	} from 'vue'
	import localService from "@/service"
	import store from "@/store"
	import VueEvent from '../service/events'
	export default {
		name: 'MainHeader',
		props: {
			theme: "",
			showMenu: false,
			showBanner: false,
			showSearch: true,
			menuId: 0,
			txtKey: {
				type: String,
				default: ""
			},
			type: {
				type: String,
				default: ""
			},
			
		},

		data() {
			return {
				//series: ["ISO9001","ISO14001","ISO45001","ISO50430","ISO50001"],
				//,{name:"工程施工质量管理体系",value:"ISO50430"},{name:"能源管理体系",value:"ISO50001"}
				category: "ISO9001",
				certTypes: [{name:"质量管理体系",value:"ISO9001"},{name:"环境管理体系",value:"ISO14001"},{name:"职业健康安全管理体系",value:"ISO45001"}],
				selectedType:{name:"质量管理体系",value:"ISO9001"},
				left: 50,
				width: 60,
				searchPlaceHolder:"请输入关键词搜索...",
				keywords: ["五金","纺织","三体系","销售","质量认证","机械加工"],
				hyfl: [],
				selectedHyItem: {},
				clientX: '',
				clientY: '',
				fav: true,
				menu: false,
				kwords: "",
				type_filter: "",
				showCategory: false,
				showCatList: true,
				cateText: "所有行业",
				cateId1: 0,
				cateId2: 0,
				message: false,
				hints: true,
				searchText:"",
				userInfo: {
					nickname: "johon1",
					corp:"",
					headImgUrl: "/shared/images/demo/john.jpg"
				},
				hasLogin: localService.utils.isUserLogin(),
				fixedTop: false,
				hotKeywords:[],
			}
		},
		watch: {
			txtKey(newValue, oldValue) {
				this.kwords = newValue
			},
			type(newValue, oldValue) {
				this.type_filter = newValue
				console.log("new"+newValue)
			}
		},
		mounted() {
			var _this = this;
			_this.loadHyfl();
			if (_this.hasLogin) {
				_this.userInfo = localService.utils.getUserInfo()
				// this.userInfo.corp = "河北华拓建设工程技术咨询事务所有限公司"
			}
			
			if(this.showBanner=="true"){
				$(window).scroll(function(){
					_this.fixedTop=$(this).scrollTop()>100
				})
			}

			VueEvent.on("onKeywordsRefreshed",(data)=>{
				_this.hotKeywords=[]
				for(var i=0;i<data.length && i<15;i++){
					_this.hotKeywords.push(data[i])
				}
				
			})
			
			VueEvent.on("userLogin", (userObj) => {
				//alert("user==="+JSON.stringify(userObj))
				console.log("login....");
				localService.utils.setUserInfo(userObj);
				_this.hasLogin = true;
				_this.userInfo = userObj;
			})
			
		},
		beforeDestroy(){
			VueEvent.off("onKeywordsRefreshed");
			VueEvent.off("userLogin");
		},
		computed: {
		},
		methods: {
			onUserInput(v){
				if(event.keyCode==13){
					if($.trim(this.searchText).length>0){
						this.onSearchWithKey()
					}
				}
				
			},
			onKeywordClick(kword){
				
				this.onSearchWithKey(kword)
			},
			onSearchWithKey(kword){
				//var _kword=kword?kword:this.searchText
				var _kword=this.searchText
				//alert("kword="+_kword)
				if(this.$router.currentRoute.path=="/search"){
					this.onSearch({
						key: _kword,
						reload: 1
					})
				}else{
					let data = localService.utils.encodeCode(JSON.stringify({
						key: _kword
					}))
					this.$router.push({
						path: "/search",
						query: {filter:data}
					}, ()=>{}, ()=>{})
				}
			},
			onSearch(args) {
				let params={}
				if(args && !args.isTrusted){
					for(var k in args){
						params[k]=args[k]
					}
				}else{
					params.key=this.kwords
				}

				//alert("args=="+JSON.stringify(args)+",params=="+JSON.stringify(args))
				
				if(!params.key){
					if(this.kwords!="")
						params.key=this.kwords
				}
				if(!params.type){
					params.type=this.category
				}

				VueEvent.emit("filterTemplates",params);
				
				
			},
			
			linkTo(path){
				if(path == "/"){
					this.$router.push({
						path: path,
						query: {}
					}, ()=>{}, ()=>{})
				}else{
					if(localService.utils.isUserLogin()){
						this.$router.push({
							path: path,
							query: {}
						}, ()=>{}, ()=>{})
					}else{
						VueEvent.emit("execLogin");
					}
				}
			},
			login() {
				VueEvent.emit("execLogin");
			},
			logout() {
				var _this = this;
				console.log("logout....")
				localService.utils.setUserInfo();
					_this.hasLogin = false;
					_this.$router.push({
						path: "/",
						query: {}
					}, ()=>{}, ()=>{})
				/*
				localService.http.request({
					url: "/logout",
					params: {
					}
				}).then(res => {
					localService.utils.setUserInfo();
					_this.hasLogin = false;
					_this.$router.push({
						path: "/",
						query: {}
					}, ()=>{}, ()=>{})
				})
				*/
			},
			
			categoryClick(item){	
				this.selectedType=item	
				//alert("type=="+JSON.stringify(this.selectedType))
				if(this.$router.currentRoute.path=="/search"){
					this.onSearch({
						type: item.value,
						reload: 1
					})
				}else{
					let data = localService.utils.encodeCode(JSON.stringify({
						type: item.value
					}))
					this.$router.push({
						path: "/search",
						query: {filter:data}
					}, ()=>{}, ()=>{})
				}
				

			},

			
			loadHyfl() {
				//this.$store.commit("setUserInfo",{name:"test1",age:10})
				let inst = this
				localService.http.request({
					url: "/common/js/cert_hyfl.json",
					params: {}
				}).then(res => {
					inst.hyfl=[{id:"0000",name:"所有行业"}]
					for(var i=0;i<res.data.children.length;i++){
						inst.hyfl.push(res.data.children[i])
					}
					/*
					if(this.$route.query && this.$route.query.certType){ //从详情页选择认证类别跳转
						this.categoryClick(this.$route.query.certType)	
					}else if(this.$route.query && this.$route.query.userKey){ //从详情页选择认证类别跳转
						this.kwords=this.$route.query.userKey
						this.onSearch()
					}else{
						inst.onSearch({type:inst.category})
					}*/
					
				});
			},
			showSubItem(item) {
				this.selectedHyItem = item;
				this.cateId1 = item.id;
				this.cateId2 = 0;
			},
			show(event) {
				let targetNode = event.target.nodeName == "IMG" ? event.target.parentNode : event.target
				//console.log("父组件传过来的event", event);
				let x = event.x; //鼠标点击的x坐标
				let y = event.y; //鼠标点击的y坐标
				console.log(x,y)
				let menuWidth = 400; //菜单宽度，这个与.popup-menu样式对应的宽度一致
				let menuHeight = 350; //this.menuData.length * 30 + 20; //菜单高度，根据菜单项自动计算的高度

				//+parseFloat(targetNode.clientWidth/3)
				this.clientX = (parseFloat(x) - 60) + "px"; //(targetNode.offsetLeft + 5) + "px" 
				this.clientY = (targetNode.offsetTop + targetNode.clientHeight + 7) + "px"; // (parseFloat(y) + 10) + "px";

				let windowWidth = document.documentElement.clientWidth; // 实时屏幕宽度
				let windowHeight = document.documentElement.clientHeight; // 实时屏幕高度

				if (parseFloat(x) + menuWidth > windowWidth) {
					this.clientX = (parseFloat(windowWidth) - menuWidth - 50) + "px";
				}
				if (parseFloat(y) + menuHeight > windowHeight) {
					this.clientY = (parseFloat(windowHeight) - menuHeight - 50) + "px";
				}
				this.showCategory = true;
				event.stopPropagation(); //阻止事件冒泡
				document.addEventListener("click", this.closeMenu, false); // 添加关闭事件
				document.addEventListener("mousemove", this.closeMenu, false); //销毁关闭事件
				window.addEventListener("scroll", this.closeMenu, false)
			},
			isInPopup(node) {
				let tNode = node
				while (tNode.nodeName != "BODY") {
					tNode = tNode.parentNode
					if (!tNode)
						return false
					if (tNode.className && (tNode.className.indexOf("menu-dropdown") >= 0 || tNode.className.indexOf(
							"search-area") >= 0))
						return true
					//onsole.log("\t--"+tNode.nodeName+"=="+tNode.className)
				}
				return false
			},
			closeMenu() {
				let isInMenu = this.isInPopup(event.target)
				if (isInMenu) {
					//console.log("菜单event:");
					return
				}

				if (event.type == "mousemove") {
					let nodes = document.getElementsByClassName("menu-dropdown")
					if (nodes.length > 0) {
						let menuNode = nodes[0]
						//console.log("menuNode::",menuNode)
						//console.log("x="+menuNode.offsetLeft+",y="+menuNode.offsetTop+",w="+menuNode.clientWidth+",h="+menuNode.clientHeight+",mx="+event.clientX+",my="+event.clientY)
						let x = event.clientX
						let y = event.clientY
						if (x >= menuNode.offsetLeft - 1 && x <= menuNode.offsetLeft + menuNode.clientWidth + 1 &&
							y >= menuNode.offsetTop - 5 && y <= menuNode.offsetTop + menuNode.clientHeight) {
							//console.log("in ret....")
							return
						}
					}
				}

				console.log("销毁监听事件。type=" + event.type);
				document.removeEventListener("click", this.closeMenu, false); //销毁关闭事件
				document.removeEventListener("mousemove", this.closeMenu, false); //销毁关闭事件
				window.removeEventListener("scroll", this.closeMenu, false)
				this.showCategory = false;
			},
			/*
			industryItemClick(item) {
				this.selectedHyItem = item;
				this.cateId1 = item.id;
				if(item.name.length>=12){
					this.cateText = item.name.substr(0,10)+"...";
				}else{
					this.cateText = item.name;
				}
				
				let id = event.target.getAttribute("data-id"); //获取菜单项id
				//this.$emit('menuClick', id);//传参调用父组件事件，让父组件知道是点击到哪个菜单项
				//event.stopPropagation();//阻止事件冒泡
				console.log("销毁监听事件。");
				document.removeEventListener("click", this.closeMenu, false); //销毁关闭事件
				document.removeEventListener("mousemove", this.closeMenu, false); //销毁关闭事件
				window.removeEventListener("scroll", this.closeMenu, false)
				this.showCategory = false;
				this.onSearch({industry:item.name});
			}*/
		}
	}
</script>
<template>
	<div class="layout_main">
		<div :class="!fixedTop && showBanner=='true'?'top':'top fixedMenu'">
			<div class="wrap">
				<el-row :gutter="4" style="width:100%">
					<el-col :span="4" class="grid-col">
						<div class="left" @click="linkTo('/')">
							<img src="../assets/images/title_new.png" />
						</div>
					</el-col>
					<el-col :span="7" class="grid-col">
						<div>&nbsp;
							<div class="center" >
								<ul v-if="showMenu&&hasLogin">
									<li :class="menuId==0?'on':''"><a @click="linkTo('/')">首页</a></li>
									<li :class="menuId==1?'on':''"><a @click="linkTo('/project')">我的项目</a></li>
									
									<li :class="menuId==4?'on':''"><a @click="linkTo('/favs')">我的收藏</a></li>
								</ul>
								<ul v-else class="not-login">
									<li :class="menuId==0?'on':''"><a @click="linkTo('/')">首页</a></li>
									<li :class="menuId==1?'on':''" class="disabled"><a >我的项目</a></li>
									<li :class="menuId==4?'on':''" class="disabled"><a >我的收藏</a></li>
								</ul>
							</div>
						</div>
					</el-col>
					
					<el-col :span="5" class="grid-col">
						<div>
							&nbsp;
							<div class="search" v-if="!(showBanner=='true' && !fixedTop)">
								<div class="search-content">
								
									<el-input  v-model="searchText" 
											class="input_box"
											size="small"
											maxlength="25"
											:placeholder="this.searchPlaceHolder"
											@keyup.native="onUserInput()"
											>
									</el-input>
									<el-button type="text" class="custom-button"  plain icon="el-icon-search" @click="onSearchWithKey"></el-button>
									
									
								</div>
							</div>	
						</div>
						
					</el-col>
					<el-col :span="4" class="grid-col">
						<div>&nbsp;
							<div class="cate-list" >
								<el-dropdown @command="categoryClick">
									<span class="el-dropdown-link">
										{{selectedType.name}}<i class="el-icon-arrow-down el-icon--right"></i>
									</span>
									<el-dropdown-menu slot="dropdown" class="menu">
										<el-dropdown-item v-for="(item, index) in certTypes" :command="item" :key="index">{{item.name}}</el-dropdown-item>
									</el-dropdown-menu>
								</el-dropdown>
							</div>
						</div>
						
					</el-col>
					<el-col :span="4" class="grid-col">
						<div class="right">
							<span v-if="!hasLogin" @click="login">手机扫码登录</span>
							<div class="user" v-else >
								<div class="user-text">
									<img :src="userInfo.headImgUrl" :alt="userInfo.nickname">
									<div class="user-name">
										<h2>{{userInfo.nickname}}</h2>
										<p v-if="userInfo.corp!=''">{{userInfo.corp}}</p>
									</div>
								</div>
								<div class="user-menu">
									<el-button @click="logout" type="text" class="btn-logout">退出登录&nbsp;<i class="el-icon-arrow-right"></i></el-button>
								</div>
							</div>
						</div>
					</el-col>
				</el-row>
			</div>
		</div>
		<transition name="fade">
			<div class="banner" v-if="showBanner=='true' && !fixedTop">
				<div class="search_area">
					<div class="search-input">
						<el-input
							class="user-input"
							placeholder="请输入内容"
							prefix-icon="el-icon-search"
							v-model="searchText"
							@keyup.native="onUserInput()"
							maxlength="36">
						</el-input>
						<el-button type="text"  plain size="small" @click="onSearchWithKey()" class="user-btn">搜索</el-button>
					</div>
					<div class="search-history">
						<span v-for="(item, index) in hotKeywords" :key="index">
							<el-link class="keyword" @click="onKeywordClick(item.name)">{{item.name}}</el-link>
						</span>
						
					</div>
				</div>
				<div class="slides">
					<div class="main-grid">
						<el-carousel :interval="3000" arrow="always" class="carousel">
							<el-carousel-item class="carousel-item">
								<el-row :gutter="20">
									<el-col :span="15">
										<div class="hero-text  p-0">
											<h1><span style="color: #aaa;"><b style="color:#fff;">多</b>快好省</span>案例丰富</h1>
											<p>海量案例供您选择<br>覆盖各个行业 <br> 销售、软件开发、系统集成、五金、教育、建筑...<br>一个都不能少</p>
											
										</div>
									</el-col>
									<el-col :span="9" class="img-container">
										<img src="../assets/images/s3_2.png"  class="img1">
									</el-col>
								</el-row>
							</el-carousel-item>
							<el-carousel-item class="carousel-item">
								<el-row :gutter="20">
									<el-col :span="15">
										<div class="hero-text p-0">
											<h1><span style="color: #aaa;">多<b style="color:#fff;">快</b>好省</span>向导式操作</h1>
											<p>无任何学习门槛<br> 智能匹配、轻松使用 <br> 按提示一步到底，大幅度降低文档准备时间</p>
											
										</div>
									</el-col>
									<el-col :span="9" class="img-container">
										<img src="../assets/images/s2_2.png"  class="img2">
									</el-col>
								</el-row>
							</el-carousel-item>
							<el-carousel-item class="carousel-item">
								<el-row :gutter="20">
									<el-col :span="15">
										<div class="hero-text  p-0">
											<h1><span style="color: #aaa;">多快<b style="color:#fff;">好</b>省</span>文档自动生成</h1>
											<p>数据即文档<br>格式规范统一 <br> 内审、管理评审，应有尽有<br>稍作调整，即可交付</p>
											
										</div>
									</el-col>
									<el-col :span="9" class="img-container">
										<img src="../assets/images/s1.png"  class="img3">
									</el-col>
								</el-row>
							</el-carousel-item>
							<el-carousel-item class="carousel-item">
								<el-row :gutter="20">
									<el-col :span="15">
										<div class="hero-text  p-0">
															
											<h1><span style="color: #aaa;">多快好<b style="color:#fff;">省</b></span>释放自我，让您更专注</h1>
											<p>省时省力，不减质量<br>摆脱枯燥的简单劳动 <br> 精英生活由此开始</p>
											
										</div>
									</el-col>
									<el-col :span="9" class="img-container">
										<img src="../assets/images/s4.png"  class="img4">
									</el-col>
								</el-row>
							</el-carousel-item>
						</el-carousel>
					</div>
					<div class="sub-grid">
						<div class="content1">
							<div class="title">我创建的项目</div>
							<div class="sub_title">
								<el-button size="small"class="user-button" type="text" @click="linkTo('/project')">查看详情&gt;&gt;</el-button>
							</div>
						</div>
						<div class="content2">
							<div class="title">我收藏的模板</div>
							<div class="sub_title">
								<el-button size="small" class="user-button" type="text" @click="linkTo('/favs')">查看详情&gt;&gt;</el-button>
							</div>
						</div>
					</div>
				</div>
			</div>
		</transition>
	</div>
</template>

<style scoped>
	/* 定义动画 */
	/*
	.fade-enter-active, .fade-leave-active {
		transition: opacity .5s;
	}
	.fade-enter, .fade-leave-to {
		opacity: 0;
	}
	*/

	.layout_main{
		display: flex;
		flex-direction: column;
	}

	.top {
		width: 100vw;
		background-color: #fff;
		border-bottom: solid 1px #dcdcdc;
	}


	.banner{
		padding-top: 15px;
		width: 100%;
		height:360px;
		background-image: url("../assets/images/banner-bg.png");
		background-size: 100% 100%;
		color: #fff;
		display: flex;
		flex-direction: column;
		margin-top: 0px;
		.search_area{
			height: 150px;
			border: solid 0px orange;
			display: flex;
			flex-direction: column;
			justify-content: center;
			align-items: center;
			.search-title{
				height:40px;
				line-height: 50px;
				font-size: 18px;
				font-weight: normal;
				font-family: 微软雅黑;
				display: none;
			}
			.search-input{
				height: 60px;
				line-height: 60px;
				position: relative;
				.user-input{
					font-size: 15px;
					width: 40vw;
				}
				.user-btn{
					background-color: #073b92;
					position: absolute;
					right: 5px;
					top: 15px;
					z-index: 20;
					color: #b5e3fe;
					border-radius: 3px;
					width: 60px;
				}
				.user-btn:hover{
					color: #ffffff;
				}
				
			}
			.search-history{
				height: 40px;
				line-height: 30px;
				span{
					margin: 3px 5px;
					font-size: 15px;
					cursor: pointer;
				}
				.keyword{
					color: #fff;
				}
				.keyword:hover{
					color: orange;
					text-decoration: none !important;
				}
			}
		}
		.slides{
			
			height: 240px;
			border: solid 0px red;
			margin: 0vw 10vw;
			display: flex;
			flex-direction: row;
			.main-grid{
				border: solid 0px yellow;
				background-image: url("../assets/images/slides-bg1.png");
				background-size: 100% 100%;
				width: 83%;
				margin-right: 1%;
				height: 88%;
				border-radius: 5px;
				.carousel{
					height: 100%;
					margin: 0px !important;
					overflow-y: hidden;
					.carousel-item{
						padding: 10px 10px;
						.hero-text{
							
							text-align: left;
							padding: 1px 0px 1px 100px;
							h1 {
								line-height: 28px;
								font-size: 24px;
								font-weight: 700;
								color: #ffffff;
								margin-bottom: 10px;
								span {
									font-size: 18px;
									display: block;
									margin-bottom: 3px;
									color: #fff;
									font-weight: 700;
									line-height: initial;
									letter-spacing: 3px;
								}
							}
							p {
								color: #fff;
								margin-bottom: 35px;
							}
						}
						img{
							height: 150px !important;
							margin: 12px 12px;
							opacity: 1.0;
						}
					}
				}
			}
			.sub-grid{
				border: solid 0px orange;
				width: 16%;
				height: 88%;
				.content1{
					border-radius: 5px;
					height: 47%;
					background-color: #004aa5;
					margin-bottom: 4%;
					position: relative;
					background-image: url(../assets/images/icon1.png);
					background-repeat: no-repeat;
					background-size: 50% 50%;
					background-position: right bottom;
					.title{
						font-weight: normal;
						font-size: 16px;
						position: absolute;
						left: 12px;
						top: 12px;
					}
					.sub_title{
						position: relative;
						.user-button{
							position: absolute;
							top: 42px;
							left: 10px;
							color: #fff;
						}
						.user-button:hover{
							color: #efefef;
							text-decoration: underline;
						}
					}
				}
				.content2{
					border-radius: 5px;
					background-color: #004aa5;
					height: 47%;
					background-repeat: no-repeat;
					background-size: 50% 50%;
					background-position: right bottom;
					background-image: url(../assets/images/icon2.png);
					position: relative;
					.title{
						font-weight: normal;
						font-size: 16px;
						position: absolute;
						left: 12px;
						top: 12px;
					}
					.sub_title{
						position: relative;
						.user-button{
							position: absolute;
							top: 42px;
							left: 10px;
							color: #fff;
						}
						.user-button:hover{
							color: #efefef;
							text-decoration: underline;
						}
					}
				}
			}

		}

		.img-container{
			
		}
	}

	
	

	.fixedMenu{
		position: fixed;
		top: 0;
		z-index: 10;
		/*box-shadow: 5px 5px 8px rgba(0, 0, 0, .2);*/
		height: 72px;
		
	}
	
	/*
	.top.light {
		background-color: #fff;
		color: #fff;
		border-bottom: 1px solid rgba(255, 255, 255, .15);
		position: absolute;
		background-size: cover;
		background-position: top;
		
		
	}*/

	.top .wrap {
		width: 85vw;
		min-width: 1200px;
		margin: 0 auto;
		display: flex;
		flex-direction: row;
		flex-wrap: nowrap;
		justify-content: space-between;
		height: 72px;
		
		.grid-col{
			border: solid 0px yellow;
		}
	}
	.top .wrap .left {
		padding-top: 19px;
		cursor: pointer;
	}
	.top .wrap .left img {
		display: block;
	}
	.top .wrap .center{
		padding: 0px 15px;
		text-align: left;
		display: inline-block;
		ul{
			padding: 0;
			margin: 0;
			list-style: none;
			line-height: 71px;
			li {
				display: inline-block;
				position: relative;
				margin-left: 15px;
				min-width: 100px;
				text-align: center;
				a {
					display: block;
					padding: 0 0px;
					color: inherit;
					cursor: pointer;
					color: #000;;
				}

				a:hover {
					background: #f8f8f8;
				}

				.on {
					color: #1b66e6;
				}
			}

			li.on::after {
				content: "";
				display: block;
				width: 32px;
				height: 2px;
				background: #000;
				position: absolute;
				bottom: 0px;
				left: 50%;
				margin-left: -16px;
			}
		}

		ul.not-login{
			.disabled{
				a {
					color: #a0a0a0 !important;
				}
			}
			li.on::after {
				display: none;
			}
		}
	}
	.top .wrap .cate-list{
		height: 36px;
		line-height: 36px;
		margin: 18px 0;
		margin-left: 0px !important;
		position: relative;
		text-align: right;
		display: inline-block;
		padding: 0px 10px;
		border: solid 1px #dcdcdc;
		border-radius: 18px;
		width: 192px;
		.el-dropdown-link{
			color: #555;
			font-weight: bold;
		}
		
		

		/*
		:deep(.el-dropdown-menu__item) {
			background-color: transparent !important;  
			color: rgb(143, 140, 143) !important;      
		}
 
		:deep(.el-dropdown-menu__item:hover) {
			background-color: transparent !important;  
			color: rgb(143, 140, 143) !important;      
		}

		:deep(.el-dropdown-menu__item.is-active) {
			background-color: transparent !important;  
			color: rgb(143, 140, 143) !important;      
		}
		*/
	}

	
	.top .wrap .search{
		width: 270px;
		height: 36px;
		line-height: 36px;
		font-size: 13px;
		margin: 18px 0;
		position: relative;
		display: inline-block;
		border: solid 1px #dcdcdc;
		border-radius: 18px;
		
		.input_box {
			float: left;
			width: 82%;
			margin-left: 10px;
			& /deep/ .el-input__inner {
				border: none;
			}
		}

		.custom-button {
			background-color: transparent; /* 背景色 */
			color: #dcdcdc; /* 文本颜色 */
			border-color: transparent; /* 边框颜色 */
			padding: 5px; /* 内边距 */
			font-size: 16px; /* 字体大小 */
			border-radius: 4px; /* 边框圆角 */
		}
 
		.custom-button:hover {
			color: #000;
		}
	}

	.top .wrap .right{
		position: relative;
		
	}
	.top .wrap .right span {
		display: block;
		text-align: center;
		background-color: #c7000b;
		font-size: 14px;
		width: 120px;
		line-height: 28px;
		margin-top: 22px;
		cursor: pointer;
		position: absolute;
		right: 35%;
		border-radius: 20px !important;
		color: #fff;
		
	}
	.top .wrap .right span:hover {
		background-color: #d64a52 !important;
	}
	
	.top .wrap .right .user{
		display: flex;
		flex-wrap: nowrap;
		line-height: 70px;
		justify-content: center;
		
	}
	.top .wrap .right .user .user-text{
		display: flex;
		padding: 0 10px;
		cursor: pointer;
		color: #000;
	}
	.top .wrap .right .user .user-text:hover{
		background: rgba(27, 102, 230, .1);
	}
	.top.light .wrap .right .user .user-text:hover{
		background: rgba(255, 255, 255, .1);
	}
	.top .wrap .right .user img {
		width: 40px;
		height: 40px;
		border-radius: 20px;
		background: #ccc;
		margin-top: 15px;
	}
	.top .wrap .right .user .user-name{
		margin: 0 0 0 10px;
		height: 70px;
		display: flex;
		flex-direction: column;
		justify-content: CENTER;
	}
	.top .wrap .right .user .user-name h2{
		font-size: 13px;
		line-height: 20px;
		font-weight: normal;
	}
	.top .wrap .right .user .user-name p{
		font-size: 12px;
		line-height: 20px;
		margin: 0;
	}
	.top .wrap .right .user .user-menu{
		cursor: pointer;
		padding:  0 0px;
		position: relative;
		.btn-logout{
			color: #000;
			i{
				top: 24px;
				color: #000;
				position: absolute;
			}
		
		}
		.btn-logout:hover{
			color: #c7000b;
			text-decoration: underline;
			
		}

	}
	.top .wrap .right .user .user-menu:hover{
		/*background: rgba(27, 102, 230, .1);*/
	}
	.top.light .wrap .right .user .user-menu:hover{
		/*background: rgba(255, 255, 255, .1);*/
	}
	.top .wrap .right .user .user-menu i{
		vertical-align: sub;
		color: #666;
	}
	.top.light .wrap .right .user .user-menu i{
		color: #fff;
	}
	.top .wrap .right .user .user-menu .menuCont{    
		position: absolute;
		height: 0;
		transition: all .2s;
		overflow: hidden;
		right: 0;
		line-height: 28px;
		width: 120px;
		text-align: center;
		font-size: 12px;
		background: #fff;
		color: #333;
		border-radius: 1px;
		
	}
	.top .wrap .right .user .user-menu .menuCont p{
		margin: 0;
		padding: 6px 10px;
		text-align: left;
	}
	.top .wrap .right .user .user-menu .menuCont p:hover{
		background: rgba(27, 102, 230, .1);
	}
	.top .wrap .right .user .user-menu .menuCont p i{
		color: #666;
		font-size: 20px;
		vertical-align: sub;
		margin-right: 5px;
	}
	.top .wrap .right .user .user-menu .menuCont p i.mdi-logout{
		font-size: 18px;
	}
	.top .wrap .right .user .user-menu:hover .menuCont{
		height: auto;
		padding: 10px 0;
		box-shadow: 0 2px 10px rgba(0,0,0,.2);
	}
	
	.v-enter-active,
	.v-leave-active {
		transition: opacity 0.5s ease;
	}

	.v-enter-from,
	.v-leave-to {
		opacity: 0;
	}

	.container-main {
		width: 100vw;
		height: 250px;
		display: flex;
		place-items: center;
		justify-content: center;
		flex-direction: column;
		/*background-image: url(/src/assets/home-bg.jpg);*/
		background-size: cover;
		background-position: top;
		padding-top: 70px;
		/* margin-top: -70px; */
	}
	.container-main .series{
		width: 770px;
		color: #333;
		font-size: 15px;
		text-align: center;
	}
	.container-main .series span{
		display: inline-block;
		margin: 0 16px;
		padding: 0 0 10px 0;
		cursor: pointer;
	}
	.container-main .series span:hover{
		color: #FFC107;
	}
	.container-main .series span.on{
		/* border-bottom: 2px solid #FFC107; */
		color: #FFC107;
	}
	.container-main .slide{
		width: 770px;
		height: 2px;
		position: relative;
	}
	.container-main .slide span{
		position: absolute;
		display: block;
		height: 2px;
		background: #FFC107;
		transition: all .2s;
	}
	.container-main .keywords {
		color: #333;
		font-size: 14px;
		margin-top: 20px;
		text-align: center;
	}

	.container-main .keywords span {
		margin: 0 10px;
		cursor: pointer;
		padding: 3px 12px;
		border-radius: 2px;
		background: rgba(255,255,255,.2);
	}
	.container-main .keywords span:hover{
		/* text-decoration: underline; */
		background: rgba(255,255,255,.4);
	}

	/*
	.search-area {
		width: 770px;
		height: 50px;
		display: flex;
		background-color: #fff;
		border: solid 1px #efefef;
		padding: 5px 5px 5px 20px;
		border-radius: 5px;
	}
	.search-area .btn-search{
		background: #1b66e6;
		box-shadow: none;
		height: 38px;
	}
	.search-area .btn-search i{
		color: #fff;
	}
	*/
	.menu-dropdown {
		position: absolute;
		left: 40%;
		top: 140px;
		z-index: 999;
		display: flex;
		font-size: 14px;

		::-webkit-scrollbar {
		  /*滚动条整体样式*/
		  width: 8px; /*高宽分别对应横竖滚动条的尺寸*/
		  height: 1px;
		}
		::-webkit-scrollbar-thumb {
		  /*滚动条里面小方块*/
		  border-radius: 10px;
		  height: 20px;
		  background: #a5acb2;
		}
		::-webkit-scrollbar-track {
		  /*滚动条里面轨道*/
		  -webkit-box-shadow: inset 0 0 0 rgba(0, 0, 0, 0);
		  border-radius: 10px;
		  background: #ffffff;
		}
	}
	.menu-dropdown .menu{
		width: 630px;
		background: #fff;
		height: 100%;
		margin-right: 1px;
		box-shadow: 0 0 5px rgba(0,0,0,.2);
		padding: 5px 0;
		padding-bottom: 15px;
	}
	.menu-dropdown .menu ul{
		list-style: none;
		padding: 0 15px 0 5px;
	}
	.menu-dropdown .menu ul li{
		float: left;
		text-align: center;
		padding: 0 10px;
		background: #f6f6f6;
		margin-left: 10px;
		margin-top: 10px;
		line-height: 26px;
		border-radius: 2px;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		color: #333;
	}
	.menu-dropdown .menu ul li a{
		color: inherit;
	}
	.menu-dropdown .menu ul li:hover{
		background: rgba(27, 102, 230, .1);
		color: #1b66e6;
	}
	.menu-dropdown .menu ul li.on{
		background: #1b66e6;
		color: #fff;
	}
	
	.menu-dropdown .menuWrap{
		width: 200px;
		height: 354px;
		background: #fff;
		overflow-y: scroll;
		margin-right: 1px;
		box-shadow: 0 0 5px rgba(0,0,0,.2);
		padding: 5px 0;
		line-height: 32px;
	}
	.menu-dropdown .menuWrap ul {
		list-style: none;
		padding: 0;
	}
	.menu-dropdown .menuWrap ul li{
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
		padding: 0 5px 0 20px;
		color: #333;
		display: flex;
		flex-direction: row;
		justify-content: space-between;
	}
	.menu-dropdown .menuWrap ul li.on{
		background: rgba(27, 102, 230, .1);
		color: #1b66e6;
	}
	.menu-dropdown .menuWrap ul li:not(:first-child):hover{
		background: rgba(27, 102, 230, .1);
		color: #1b66e6;
	}
	.menu-dropdown .menuWrap ul li:first-child{
		font-weight: bold;
		color: #1b66e6;
	}
	.menu-dropdown .menuWrap ul li i{
		font-size: 20px;
		color: #999;
	}
	.menu-dropdown .menuWrap ul li a {
		color: initial;
		text-decoration: none;
		white-space: nowrap;
		overflow: hidden;
		text-overflow: ellipsis;
	}
</style>
<style>
.menu li:hover{
	color: #c7000b !important;
	background-color:#f8f8f8 !important;
}
</style>
